function GetURLParameter(sParam)
{
  var sPageURL = window.location.search.slice(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) 
  {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) 
      {
          return sParameterName[1];
      }
  }
}

function App() {
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1Mii6zLmNHYcpXdb22dkT9t8"
      publishable-key="pk_test_51McDloLmNHYcpXdbEW5dY67TYH6FxyS6MY9vv8L6jhTmQeFQqa6wt1cTu2XQNHyZo6zZWoyCQ1eLgxKAStla7zLu00Ht3g7kia"
      customer-email={ GetURLParameter('email') }
    > 
    </stripe-pricing-table>
  );
}

export default App;